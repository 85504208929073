import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../../components/Layout"
import { Heading, Featured } from "../../components/utils"
import { Projects } from "../../components/projects"

const ProjectsPage = ({ data }) => {
  const featured = data.featured.nodes[0]

  return (
    <Layout transparentHeader={!!featured}>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.projectsPage.locale,
        }}
        seo={data.projectsPage.seoMetaTags}
      >
        {data.projectsPage._allSlugLocales.map((version, index) => (
          <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/${version.value}`} key={index} />
        ))}
      </HelmetDatoCms>
      <div className="category">
        {featured &&
          <Featured
            data={featured}
            title={featured.title}
            subtitle={`${featured.city.name}, ${featured.city.country.name}`}
            image={featured.gallery[0].fluid}        
          />
        }
        <div className="container py-12 md:py-24">
          <Heading title={data.projectsPage.title} className="mb-12" />
          <Projects projects={data.projects.nodes} featured={featured} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectsPageQuery($locale: String!, $slug: String!) {
    projectsPage: datoCmsProjectsPage(
      locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      ...ProjectsPage
    }
    featured: allDatoCmsProject(
      filter: {
        locale: { eq: $locale }
        featured: { eq: true }
        slug: { ne: null }
      }
      sort: { fields: meta___createdAt, order: DESC }
      limit: 1
    ) {
      nodes {
        ...Project
      }
    }
    projects: allDatoCmsProject(
      filter: { locale: { eq: $locale }, slug: { ne: null } }
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      nodes {
        ...Project
      }
    }
  }
`

export default ProjectsPage
