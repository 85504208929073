import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { linkResolver } from "../../utils/linkResolver"

export const Projects = ({ projects, featured }) => (
  <>
    <div className="lg:flex lg:flex-wrap lg:-mx-4">
      {projects
        .filter(x => (!featured ? true : x.originalId !== featured.originalId))
        .map((item, key) => (
          <div className="mb-6 lg:w-1/3 lg:px-4 lg:mb-8" key={key}>
            <Link to={linkResolver(item)}>
              <div className="h-full overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg">
                <Img
                  fluid={item.gallery[0].fluid}
                  style={{
                    height: 224,
                    width: "100%",
                  }}
                  alt={item.title}
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 font-headline">
                    {item.title}
                  </h3>
                  <p className="mt-1 text-gray-500 text-md">
                    {item.city.name}, {item.city.country.name}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </div>
  </>
)
